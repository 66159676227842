<!--
 * @Auth: linjituan
 * @Date: 2022-08-01 15:26:31
 * @LastEditors: linjituan
 * @LastEditTime: 2022-11-17 11:34:15
-->
<template>
  <div class="rtc-wrapper" style="height: 100%">
    <!-- <video id="preview_video" style="width: 100%; poster="@/assets/images/video/refresh.png" height: 100%" autoplay></video> -->
    <video @mouseover="overEvent" @mouseout="outEvent" :id="videoId" style="width: 100%; height: 100%" autoplay></video>
    <!-- <div class="video-tool" v-show="showTools">
      <div class="tool-item" @mouseover="showTools = true" @click="refresh()" title="刷新">
        <img class="img" src="@/assets/images/video/refresh.png" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
import '@/assets/plugins/iot-sdk.js'
import RtcVoice from './RtcVoice'

export default {
  props: {},
  mixins: [RtcVoice],
  data() {
    return {
      // showTools: false
    }
  },
  mounted() {
    console.log('rtc video mounted')
    // this.init()
  },
  methods: {
    init() {
      this.openVideo()
    },
    overEvent() {
      this.$emit('overEvent')
    },
    outEvent() {
      this.$emit('outEvent')
    }
  }
}
</script>

<style lang="less" scoped>
.rtc-wrapper {
  position: relative;
  .video-tool {
    position: absolute;
    bottom: 5px;
    right: 0;
    .tool-item {
      margin-right: 10px;
      cursor: pointer;
      .img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
